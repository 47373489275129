import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAllLoans } from "../../../redux/actions/AdminAction";
import { SyncData } from "../../../redux/actions/BucketAction";
import Loader from "../../../assets/lottie/Loader.jsx";
import Sidebar from "../../../components/Sidebar";
import Header from "../../../components/Header";
import Moment from "moment";

const AllLoans = () => {
  const [loanStatus, setloanStatus] = useState("Active");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    getAllLoans({ id }).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, [loanStatus, id]);


  const navigate = useNavigate();
  return (
    <div className="flex">
      <Sidebar />
      {data === null ? (
        <Loader size={300} />
      ) : (
        <div className="w-full">
          <Header>
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center w-full pb-4">
                <div className="flex items-center">
                  <div>
                    <div className="text-yellow-500 font-extrabold flex items-center text-2xl">
                      {data[0]?.displayName ? data[0]?.displayName : ""}
                    </div>
                    <div className="text-gray-400 font-medium flex items-center text-sm">
                      {data[0]?.mobileNo ? data[0]?.mobileNo : ""}
                    </div>
                  </div>
                  <div>
                    <div className="text-slate-600 ml-5 font-medium flex items-center text-sm">
                      ({id})
                    </div>
                    <div className="text-slate-600 ml-5 font-medium flex items-center text-sm">
                      Total Loans : {data?.length}
                    </div>
                  </div>
                </div>
              </div>
              {loading ? (
                <Loader size={80} />
              ) : (
                <>
                  <div
                    className={
                      loanStatus === ""
                        ? "btn bg-slate-500  hover:bg-indigo-500 cursor-pointer text-white w-48 mr-10"
                        : "btn bg-indigo-500  hover:bg-slate-500 cursor-pointer text-white w-48 mr-10"
                    }
                    onClick={() => {
                      setLoading(true);
                      if (loanStatus === "Active") {
                        setloanStatus("Closed");
                      } else {
                        setloanStatus("Active");
                      }
                    }}
                  >
                    {loanStatus === "Active"
                      ? "Show closed Loans"
                      : "Show active loans"}
                  </div>
                  <div
                    className="btn bg-indigo-500 text-white w-24"
                    onClick={() => {
                      window.confirm("Do you want to sync data ?")
                        ? SyncData(id)
                        : console.log("no");
                    }}
                  >
                    Sync
                  </div>
                </>
              )}
            </div>
          </Header>
          <div className="m-5">
            <div className="flex flex-wrap justify-between h-full white-card-inner">
              {loading ? (
                <Loader size={300} />
              ) : (
                data?.map((item) => {
                  if (item.accountType === "master") {
                    return (
                      <div
                        className="flex items-center mt-3 white-card-double"
                        style={{ height: "250px" }}
                        onClick={() => {
                          navigate(
                            `/leaddetails/${item?.locAccountId}/${item?.lmsClientId}`
                          );
                        }}
                      >
                        <div className="w-full">
                          <div className="flex items-center justify-between w-full">
                            <div className="flex flex-col w-1/2">
                              <div className="text-black font-bold flex items-center text-sm xl:text-base font-medium">
                                {"₹ " +
                                  parseFloat(
                                    item?.foreclosureAmount
                                  ).toLocaleString("en-IN", {
                                    maximumFractionDigits: 2,
                                  })}
                              </div>
                              <div className="text-gray-500 text-xs text-medium">
                                Total Outstanding
                              </div>
                            </div>
                            <div className="flex flex-col w-1/2">
                              <div className="text-black font-bold flex items-center text-sm xl:text-base font-medium ml-auto">
                                {Moment(item?.activatedOnDate).format("LL")}
                              </div>
                              <div className="text-gray-500 text-xs text-medium ml-auto">
                                Loan Start Date
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center justify-between w-full mt-6 pb-4">
                            <div className="flex flex-col w-1/2">
                              <div className="text-black font-bold flex items-center text-sm xl:text-base font-medium">
                                {"₹ " +
                                  parseFloat(
                                    item?.lastTransactionAmount
                                  ).toLocaleString("en-IN", {
                                    maximumFractionDigits: 2,
                                  })}
                              </div>
                              <div className="text-gray-500 text-xs text-medium">
                                Last Payment Amount
                              </div>
                            </div>
                            <div className="flex flex-col  w-1/2">
                              <div className="text-black font-bold flex items-center text-sm xl:text-base font-medium ml-auto">
                                {"₹ " +
                                  parseFloat(
                                    item?.principalAmount
                                  ).toLocaleString("en-IN", {
                                    maximumFractionDigits: 2,
                                  })}
                              </div>
                              <div className="text-gray-500 text-xs text-medium ml-auto">
                                Loan Amount
                              </div>
                            </div>
                          </div>
                          <div
                            className="flex items-center justify-between w-full pt-4"
                            style={{ borderTop: "1px solid black" }}
                          >
                            <div className="flex flex-col">
                              {item?.status === "Closed" ? (
                                <div className="text-gray-500 font-extrabold flex items-center text-sm xl:text-lg font-medium ml-auto">
                                  {item?.savingsProductName
                                    ? item?.savingsProductName
                                    : " "}
                                </div>
                              ) : (
                                <div className="text-yellow-500 font-extrabold flex items-center text-sm xl:text-lg font-medium ml-auto">
                                  {item?.savingsProductName
                                    ? item?.savingsProductName
                                    : " "}
                                </div>
                              )}
                              <div className="text-gray-500 text-xs text-medium">
                                Scheme
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllLoans;
